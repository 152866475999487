var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "addActivity",
      attrs: {
        title: _vm.addForm.id ? "编辑活动" : "新增活动",
        visible: _vm.visibleSaveDialog,
        width: "1000px",
        "before-close": _vm.beforeDialog
      },
      on: {
        "update:visible": function($event) {
          _vm.visibleSaveDialog = $event
        },
        close: _vm.handleAddBeforeClose
      }
    },
    [
      _c(
        "el-tabs",
        {
          staticClass: "tabControl",
          staticStyle: { height: "100%" },
          attrs: {
            "tab-position": "left",
            stretch: "",
            "before-leave": _vm.beforeTab
          },
          model: {
            value: _vm.tabsActiveName,
            callback: function($$v) {
              _vm.tabsActiveName = $$v
            },
            expression: "tabsActiveName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "基本信息", name: "1" } },
            [
              _c(
                "h4",
                {
                  staticStyle: { "margin-top": "10px", "margin-left": "10px" }
                },
                [_vm._v("基本信息")]
              ),
              _c(
                "el-form",
                {
                  ref: "addForm",
                  staticStyle: { width: "800px" },
                  attrs: {
                    model: _vm.addForm,
                    "label-width": "150px",
                    rules: _vm.addFormRules
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { flex: "1" },
                      attrs: { label: "活动名称", prop: "activityName" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入活动名称",
                          disabled: _vm.isDetail
                        },
                        model: {
                          value: _vm.addForm.activityName,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "activityName", $$v)
                          },
                          expression: "addForm.activityName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "描述", prop: "activityDescribe" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入活动描述",
                          disabled: _vm.isDetail
                        },
                        model: {
                          value: _vm.addForm.activityDescribe,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "activityDescribe", $$v)
                          },
                          expression: "addForm.activityDescribe"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.addForm.activityType === 35
                    ? _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "活动类型", prop: "activityType" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { disabled: true },
                                  model: {
                                    value: _vm.addForm.activityType,
                                    callback: function($$v) {
                                      _vm.$set(_vm.addForm, "activityType", $$v)
                                    },
                                    expression: "addForm.activityType"
                                  }
                                },
                                _vm._l(_vm.activityTypeOptions, function(item) {
                                  return _c("el-option", {
                                    key: item.dictValue,
                                    attrs: {
                                      label: item.dictLabel,
                                      value: item.dictValue
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "活动类型", prop: "activityType" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { disabled: true },
                                  model: {
                                    value: _vm.addForm.activityType,
                                    callback: function($$v) {
                                      _vm.$set(_vm.addForm, "activityType", $$v)
                                    },
                                    expression: "addForm.activityType"
                                  }
                                },
                                _vm._l(_vm.activityTypeOptions, function(item) {
                                  return _c("el-option", {
                                    key: item.dictValue,
                                    attrs: {
                                      label: item.dictLabel,
                                      value: item.dictValue
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _vm.addForm.activityType === 20 ||
                          _vm.addForm.activityType === 36 ||
                          _vm.addForm.activityType === 22 ||
                          _vm.addForm.activityType === 23
                            ? _c(
                                "el-form-item",
                                {
                                  staticStyle: { width: "370px" },
                                  attrs: {
                                    label: "活动展示名称",
                                    prop: "activityShowName"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { flex: "1" },
                                    attrs: {
                                      placeholder: "请输入活动展示名称",
                                      disabled: _vm.isDetail
                                    },
                                    model: {
                                      value: _vm.addForm.activityShowName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.addForm,
                                          "activityShowName",
                                          $$v
                                        )
                                      },
                                      expression: "addForm.activityShowName"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                  _vm.addForm.activityType === 20
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "基准时间", prop: "baseTime" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择基准时间",
                                disabled: _vm.isDetail
                              },
                              model: {
                                value: _vm.addForm.baseTime,
                                callback: function($$v) {
                                  _vm.$set(_vm.addForm, "baseTime", $$v)
                                },
                                expression: "addForm.baseTime"
                              }
                            },
                            _vm._l(_vm.baseTimeOptions, function(item) {
                              return _c("el-option", {
                                key: item.dictValue,
                                attrs: {
                                  label: item.dictLabel,
                                  value: item.dictValue
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.addForm.activityType === 36 ||
                  _vm.addForm.activityType === 22
                    ? _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: {
                                label: "素材配置",
                                prop: "materialConfigId"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择素材配置",
                                    disabled: _vm.isDetail
                                  },
                                  model: {
                                    value: _vm.addForm.materialConfigId,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.addForm,
                                        "materialConfigId",
                                        $$v
                                      )
                                    },
                                    expression: "addForm.materialConfigId"
                                  }
                                },
                                _vm._l(_vm.materialConfigOptions, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.dictValue,
                                    attrs: {
                                      label: item.dictLabel,
                                      value: item.dictValue
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: {
                                label: "计算方式",
                                prop: "calculateTypeId"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择计算方式",
                                    disabled: _vm.isDetail
                                  },
                                  model: {
                                    value: _vm.addForm.calculateTypeId,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.addForm,
                                        "calculateTypeId",
                                        $$v
                                      )
                                    },
                                    expression: "addForm.calculateTypeId"
                                  }
                                },
                                _vm._l(_vm.calculateTypeOptions, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.dictValue,
                                    attrs: {
                                      label: item.dictLabel,
                                      value: item.dictValue
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.addForm.activityType === 36
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "判断方式",
                            prop: "determinationType"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择判断方式",
                                disabled: _vm.isDetail
                              },
                              model: {
                                value: _vm.addForm.determinationType,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.addForm,
                                    "determinationType",
                                    $$v
                                  )
                                },
                                expression: "addForm.determinationType"
                              }
                            },
                            _vm._l(_vm.pdTypeOptions, function(item) {
                              return _c("el-option", {
                                key: item.dictValue,
                                attrs: {
                                  label: item.dictLabel,
                                  value: item.dictValue
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.addForm.activityType === 36
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "结算时间" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100px" },
                              attrs: {
                                placeholder: "请选择",
                                disabled: _vm.isDetail
                              },
                              model: {
                                value: _vm.addForm.settlementTimeType,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.addForm,
                                    "settlementTimeType",
                                    $$v
                                  )
                                },
                                expression: "addForm.settlementTimeType"
                              }
                            },
                            _vm._l(_vm.settleTimeTypeOptions, function(item) {
                              return _c("el-option", {
                                key: item.dictValue,
                                attrs: {
                                  label: item.dictLabel,
                                  value: item.dictValue
                                }
                              })
                            }),
                            1
                          ),
                          _vm.addForm.settlementTimeType === 2
                            ? _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    width: "100px",
                                    "margin-left": "10px"
                                  },
                                  attrs: {
                                    placeholder: "请选择",
                                    disabled: _vm.isDetail
                                  },
                                  model: {
                                    value: _vm.addForm.settlementTimeCycle,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.addForm,
                                        "settlementTimeCycle",
                                        $$v
                                      )
                                    },
                                    expression: "addForm.settlementTimeCycle"
                                  }
                                },
                                _vm._l(_vm.monthOptions, function(item) {
                                  return _c("el-option", {
                                    key: item.dictValue,
                                    attrs: {
                                      label: item.dictLabel,
                                      value: item.dictValue
                                    }
                                  })
                                }),
                                1
                              )
                            : _vm._e(),
                          _vm.addForm.settlementTimeType === 2 &&
                          _vm.addForm.settlementTimeCycle === 0
                            ? _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    width: "100px",
                                    "margin-left": "10px",
                                    "margin-right": "10px"
                                  },
                                  attrs: {
                                    placeholder: "请选择",
                                    disabled: _vm.isDetail
                                  },
                                  model: {
                                    value: _vm.addForm.settlementTimeDate,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.addForm,
                                        "settlementTimeDate",
                                        $$v
                                      )
                                    },
                                    expression: "addForm.settlementTimeDate"
                                  }
                                },
                                _vm._l(_vm.dateOptions, function(item) {
                                  return _c("el-option", {
                                    key: item.dictValue,
                                    attrs: {
                                      label: item.dictLabel,
                                      value: item.dictValue
                                    }
                                  })
                                }),
                                1
                              )
                            : _vm._e(),
                          _vm.addForm.settlementTimeType === 2 &&
                          _vm.addForm.settlementTimeCycle === 0
                            ? _c("el-time-picker", {
                                attrs: {
                                  disabled: _vm.isDetail,
                                  "picker-options": {
                                    selectableRange: "00:00:00 - 23:59:59"
                                  },
                                  "value-format": "HH:mm:ss",
                                  placeholder: "设置时间"
                                },
                                model: {
                                  value: _vm.addForm.settlementTime,
                                  callback: function($$v) {
                                    _vm.$set(_vm.addForm, "settlementTime", $$v)
                                  },
                                  expression: "addForm.settlementTime"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.addForm.activityType !== null
                    ? _c(
                        "div",
                        [
                          _vm.addForm.activityType === 22
                            ? _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { flex: "1" },
                                      attrs: { label: "图片上传" }
                                    },
                                    [
                                      _vm.isDetail
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "primary",
                                                size: "small"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.openPicture(1)
                                                }
                                              }
                                            },
                                            [_vm._v("点击查看")]
                                          )
                                        : _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "primary",
                                                size: "small"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.openPicture(1)
                                                }
                                              }
                                            },
                                            [_vm._v("添加")]
                                          )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { flex: "1" },
                                      attrs: {
                                        label: "结算时间",
                                        prop: "settlementTime"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "请选择结算时间",
                                            disabled: _vm.isDetail
                                          },
                                          model: {
                                            value: _vm.addForm.settlementTime,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.addForm,
                                                "settlementTime",
                                                $$v
                                              )
                                            },
                                            expression: "addForm.settlementTime"
                                          }
                                        },
                                        _vm._l(
                                          _vm.settleTimeTypeOptions,
                                          function(item) {
                                            return _c("el-option", {
                                              key: item.dictValue,
                                              attrs: {
                                                label: item.dictLabel,
                                                value: item.dictValue
                                              }
                                            })
                                          }
                                        ),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _c(
                                "el-form-item",
                                {
                                  staticStyle: { flex: "1" },
                                  attrs: { label: "图片上传" }
                                },
                                [
                                  _vm.isDetail
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "small"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.openPicture(1)
                                            }
                                          }
                                        },
                                        [_vm._v("点击查看")]
                                      )
                                    : _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "small"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.openPicture(1)
                                            }
                                          }
                                        },
                                        [_vm._v("添加")]
                                      )
                                ],
                                1
                              ),
                          _vm.addForm.activityType === 26
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "素材配置",
                                    prop: "materialConfigId"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择素材配置",
                                        disabled: _vm.isDetail
                                      },
                                      model: {
                                        value: _vm.addForm.materialConfigId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.addForm,
                                            "materialConfigId",
                                            $$v
                                          )
                                        },
                                        expression: "addForm.materialConfigId"
                                      }
                                    },
                                    _vm._l(_vm.materialConfigOptions, function(
                                      item
                                    ) {
                                      return _c("el-option", {
                                        key: item.dictValue,
                                        attrs: {
                                          label: item.dictLabel,
                                          value: item.dictValue
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.addForm.activityType === 26
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "导航功能",
                                    prop: "showNavigation"
                                  }
                                },
                                [
                                  _c(
                                    "el-checkbox",
                                    {
                                      attrs: {
                                        "true-label": 1,
                                        "false-label": 0,
                                        disabled: _vm.isDetail
                                      },
                                      model: {
                                        value: _vm.addForm.showNavigation,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.addForm,
                                            "showNavigation",
                                            $$v
                                          )
                                        },
                                        expression: "addForm.showNavigation"
                                      }
                                    },
                                    [_vm._v("启用")]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.addForm.activityType === 36
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "活动规则",
                                    prop: "activityRuleExplain"
                                  }
                                },
                                [
                                  _vm.isDetail
                                    ? _c(
                                        "span",
                                        [
                                          _vm.addForm.ruleTypeId == 0
                                            ? _c("span", [_vm._v("上传图片")])
                                            : _c("span", [_vm._v("文字输入")]),
                                          _vm.addForm.ruleTypeId == 0
                                            ? _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "8px"
                                                  },
                                                  attrs: {
                                                    size: "small",
                                                    type: "primary"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.openDetailPicture(
                                                        1
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("点击查看")]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _c(
                                        "el-radio-group",
                                        {
                                          on: { input: _vm.changeActivityRule },
                                          model: {
                                            value: _vm.addForm.ruleTypeId,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.addForm,
                                                "ruleTypeId",
                                                $$v
                                              )
                                            },
                                            expression: "addForm.ruleTypeId"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: 0 } },
                                            [_vm._v("上传图片")]
                                          ),
                                          _c(
                                            "el-radio",
                                            { attrs: { label: 1 } },
                                            [_vm._v("文字输入")]
                                          )
                                        ],
                                        1
                                      ),
                                  _vm.addForm.ruleTypeId
                                    ? _c("quill-editor", {
                                        ref: "text",
                                        staticClass: "editRulesBox",
                                        attrs: {
                                          options: _vm.editorOption,
                                          disabled: _vm.isDetail
                                        },
                                        model: {
                                          value:
                                            _vm.addForm.activityRuleExplain,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.addForm,
                                              "activityRuleExplain",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "addForm.activityRuleExplain"
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.addForm.ruleTypeId == 0 && !_vm.isDetail
                                    ? _c(
                                        "el-upload",
                                        {
                                          staticClass: "upload-demo",
                                          attrs: {
                                            action: "''",
                                            limit: 1,
                                            "before-remove": _vm.beforeRemove,
                                            "on-exceed": _vm.handleExceed,
                                            "http-request":
                                              _vm.handleUploadPicture,
                                            "file-list": _vm.ruleFile
                                          }
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "small",
                                                type: "primary"
                                              }
                                            },
                                            [_vm._v("点击上传")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.addForm.activityType === 2
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "抽奖展示方式",
                                    prop: "Sweepstakes"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: _vm.addForm.Sweepstakes,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.addForm,
                                            "Sweepstakes",
                                            $$v
                                          )
                                        },
                                        expression: "addForm.Sweepstakes"
                                      }
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          label: "区域一",
                                          value: "shanghai"
                                        }
                                      }),
                                      _c("el-option", {
                                        attrs: {
                                          label: "区域二",
                                          value: "beijing"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.addForm.activityType === 3
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "关联活动" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: _vm.addForm.relationActivityUuid,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.addForm,
                                            "relationActivityUuid",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "addForm.relationActivityUuid"
                                      }
                                    },
                                    _vm._l(_vm.connectedOptions, function(
                                      item
                                    ) {
                                      return _c("el-option", {
                                        key: item.secUuid,
                                        attrs: {
                                          label: item.activityName,
                                          value: item.secUuid
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.addForm.activityType === 3
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "关联活动背景图" } },
                                [
                                  _c("FileUpload", {
                                    attrs: {
                                      index: 0,
                                      url: _vm.FileUploadUrl.uploadTemp,
                                      "file-type": "jpg,jpeg,png",
                                      value: [
                                        _vm.addForm.relationVideoUploadInfoDTO
                                      ]
                                    },
                                    on: { change: _vm.reviewFileChange }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _vm.addForm.activityType === 36 ||
                              _vm.addForm.activityType === 22 ||
                              _vm.addForm.activityType === 23 ||
                              _vm.addForm.activityType === 35
                                ? _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { flex: "1" },
                                      attrs: { label: "是否重复" }
                                    },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          attrs: { disabled: _vm.isDetail },
                                          model: {
                                            value: _vm.addForm.isDuplicate,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.addForm,
                                                "isDuplicate",
                                                $$v
                                              )
                                            },
                                            expression: "addForm.isDuplicate"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: 0 } },
                                            [_vm._v("否")]
                                          ),
                                          _c(
                                            "el-radio",
                                            { attrs: { label: 1 } },
                                            [_vm._v("是")]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm.addForm.isDuplicate
                                        ? _c(
                                            "el-select",
                                            {
                                              staticStyle: {
                                                width: "100px",
                                                "margin-left": "20px"
                                              },
                                              attrs: {
                                                placeholder: "请选择",
                                                size: "mini",
                                                disabled: _vm.isDetail
                                              },
                                              model: {
                                                value:
                                                  _vm.addForm.duplicateType,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.addForm,
                                                    "duplicateType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "addForm.duplicateType"
                                              }
                                            },
                                            _vm._l(
                                              _vm.duplicateTypeOptions,
                                              function(item) {
                                                return _c("el-option", {
                                                  key: item.dictValue,
                                                  attrs: {
                                                    label: item.dictLabel,
                                                    value: item.dictValue
                                                  }
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.addForm.activityType === 35
                                ? _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { flex: "1" },
                                      attrs: { label: "连续签到" }
                                    },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          attrs: { disabled: _vm.isDetail },
                                          model: {
                                            value:
                                              _vm.addForm.isContinuousSignIn,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.addForm,
                                                "isContinuousSignIn",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "addForm.isContinuousSignIn"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: 0 } },
                                            [_vm._v("否")]
                                          ),
                                          _c(
                                            "el-radio",
                                            { attrs: { label: 1 } },
                                            [_vm._v("是")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm.addForm.activityType === 9
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "集卡方式",
                                    prop: "collectCardType"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择集卡方式" },
                                      model: {
                                        value: _vm.addForm.collectCardType,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.addForm,
                                            "collectCardType",
                                            $$v
                                          )
                                        },
                                        expression: "addForm.collectCardType"
                                      }
                                    },
                                    _vm._l(_vm.collectTypeOptions, function(
                                      item
                                    ) {
                                      return _c("el-option", {
                                        key: item.dictValue,
                                        attrs: {
                                          label: item.dictLabel,
                                          value: item.dictValue
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.promoteTaskDTOList !== [] &&
                  (_vm.addForm.activityType === 20 ||
                    _vm.addForm.activityType === 36 ||
                    _vm.addForm.activityType === 22)
                    ? _c(
                        "div",
                        _vm._l(_vm.promoteTaskDTOList, function(item, index) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              _c("div", { staticClass: "title" }, [
                                !_vm.isDetail
                                  ? _c("i", {
                                      staticClass: "el-icon-remove",
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteSublevel(
                                            index,
                                            "promoteTaskDTOList"
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                _c("div", { staticClass: "liftTitle" }, [
                                  _vm._v("提升任务 " + _vm._s(index + 1))
                                ])
                              ]),
                              _c(
                                "el-form-item",
                                { attrs: { label: "任务名称" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "200px" },
                                    attrs: {
                                      placeholder: "请输入任务名称",
                                      size: "small",
                                      disabled: _vm.isDetail
                                    },
                                    model: {
                                      value: item.taskName,
                                      callback: function($$v) {
                                        _vm.$set(item, "taskName", $$v)
                                      },
                                      expression: "item.taskName"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.addForm.activityType === 36
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "达成金额",
                                        prop: "finishMoney"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "200px" },
                                        attrs: {
                                          size: "small",
                                          placeholder: "请输入达成金额",
                                          disabled: _vm.isDetail
                                        },
                                        model: {
                                          value: item.finishMoney,
                                          callback: function($$v) {
                                            _vm.$set(
                                              item,
                                              "finishMoney",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "item.finishMoney"
                                        }
                                      }),
                                      _vm._v(" 元 ")
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.isDetail
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "权益设置" } },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "small"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.openEquity(
                                                _vm.addForm.activityType,
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("点击查看")]
                                      )
                                    ],
                                    1
                                  )
                                : _c(
                                    "el-form-item",
                                    { attrs: { label: "权益设置" } },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "small"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.openEquity(
                                                _vm.addForm.activityType,
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("设置")]
                                      )
                                    ],
                                    1
                                  )
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  (_vm.addForm.activityType === 20 ||
                    _vm.addForm.activityType === 36 ||
                    _vm.addForm.activityType === 22) &&
                  !_vm.isDetail
                    ? _c(
                        "el-button",
                        {
                          staticClass: "addLift",
                          attrs: { size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.addSublevel("promoteTaskDTOList")
                            }
                          }
                        },
                        [_vm._v("+ 添加提升任务")]
                      )
                    : _vm._e(),
                  _vm.taskForm !== [] &&
                  (_vm.addForm.activityType === 4 ||
                    _vm.addForm.activityType === 23)
                    ? _c(
                        "div",
                        _vm._l(_vm.taskForm, function(item, index) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              _c("div", { staticClass: "title" }, [
                                _c("i", {
                                  staticClass: "el-icon-remove",
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteSublevel(
                                        index,
                                        "taskForm"
                                      )
                                    }
                                  }
                                }),
                                _c("div", { staticClass: "liftTitle" }, [
                                  _vm._v("任务" + _vm._s(index + 1))
                                ])
                              ]),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "任务名称" } },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "230px" },
                                        attrs: {
                                          disabled: _vm.isDetail,
                                          placeholder: "请输入任务名称",
                                          size: "small"
                                        },
                                        model: {
                                          value: item.taskName,
                                          callback: function($$v) {
                                            _vm.$set(item, "taskName", $$v)
                                          },
                                          expression: "item.taskName"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { flex: "1" },
                                      attrs: { label: "任务类型" }
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            disabled: _vm.isDetail,
                                            placeholder: "请选择任务类型"
                                          },
                                          model: {
                                            value: item.taskType,
                                            callback: function($$v) {
                                              _vm.$set(item, "taskType", $$v)
                                            },
                                            expression: "item.taskType"
                                          }
                                        },
                                        _vm._l(_vm.taskTypeOptions, function(
                                          item
                                        ) {
                                          return _c("el-option", {
                                            key: item.dictValue,
                                            attrs: {
                                              label: item.dictLabel,
                                              value: item.dictValue
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { flex: "1" },
                                      attrs: { label: "任务banner" }
                                    },
                                    [
                                      _vm.isDetail
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "primary",
                                                size: "small"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.openPicture(
                                                    2,
                                                    index
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("点击查看")]
                                          )
                                        : _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "primary",
                                                size: "small"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.openPicture(
                                                    2,
                                                    index
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("添加")]
                                          )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              item.taskType === 0 || item.taskType === 2
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "达标金额" } },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "230px" },
                                        attrs: {
                                          disabled: _vm.isDetail,
                                          placeholder: "请输入达标金额"
                                        },
                                        model: {
                                          value: item.reachMoney,
                                          callback: function($$v) {
                                            _vm.$set(item, "reachMoney", $$v)
                                          },
                                          expression: "item.reachMoney"
                                        }
                                      }),
                                      _vm._v(" 元 ")
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.taskType === 0
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "达标天/次" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center",
                                            "justify-content": "space-between",
                                            width: "280px"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "80px" },
                                              attrs: {
                                                disabled: _vm.isDetail,
                                                placeholder: "请选择",
                                                size: "mini"
                                              },
                                              model: {
                                                value: item.reachType,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "reachType",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.reachType"
                                              }
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: { label: "天", value: 0 }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            [
                                              _c("el-input", {
                                                staticStyle: { width: "80px" },
                                                attrs: {
                                                  disabled: _vm.isDetail,
                                                  placeholder: "请输入",
                                                  size: "mini"
                                                },
                                                model: {
                                                  value: item.reachAmount,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "reachAmount",
                                                      _vm._n($$v)
                                                    )
                                                  },
                                                  expression: "item.reachAmount"
                                                }
                                              }),
                                              _vm._v(" 天")
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-checkbox",
                                            {
                                              attrs: {
                                                disabled: _vm.isDetail,
                                                "true-label": 1,
                                                "false-label": 0
                                              },
                                              model: {
                                                value: item.isContinuous,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "isContinuous",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.isContinuous"
                                              }
                                            },
                                            [_vm._v("连续")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item.taskType === 3
                                ? _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: { flex: "1" },
                                          attrs: { label: "浏览页面" }
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                disabled: _vm.isDetail,
                                                placeholder: "请选择浏览页面"
                                              },
                                              model: {
                                                value: item.browsePage,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "browsePage",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.browsePage"
                                              }
                                            },
                                            _vm._l(
                                              _vm.browsePageOptions,
                                              function(item) {
                                                return _c("el-option", {
                                                  key: item.dictValue,
                                                  attrs: {
                                                    label: item.dictLabel,
                                                    value: item.dictValue
                                                  }
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: { flex: "1" },
                                          attrs: { label: "浏览时长" }
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "compareInput",
                                            attrs: {
                                              disabled: _vm.isDetail,
                                              placeholder: "",
                                              size: "small"
                                            },
                                            model: {
                                              value: item.browseTime,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  item,
                                                  "browseTime",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression: "item.browseTime"
                                            }
                                          }),
                                          _vm._v("秒 ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { flex: "1" },
                                      attrs: { label: "权益设置" }
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "small"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.openEquity(
                                                _vm.addForm.activityType,
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("设置")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm.addForm.activityType === 4 ||
                  _vm.addForm.activityType === 23
                    ? _c(
                        "el-button",
                        {
                          staticClass: "addLift",
                          attrs: { size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.addSublevel("taskForm")
                            }
                          }
                        },
                        [_vm._v("+ 添加任务")]
                      )
                    : _vm._e(),
                  _vm.addForm.activityType === 26 && !_vm.isDetail
                    ? _c(
                        "el-button",
                        {
                          staticClass: "addLift",
                          attrs: { size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.addSublevel("aggregate")
                            }
                          }
                        },
                        [_vm._v("+ 添加链接")]
                      )
                    : _vm._e(),
                  _vm.aggregate.length > 0 && _vm.addForm.activityType === 26
                    ? _c("div", { staticStyle: { "margin-bottom": "30px" } }, [
                        _vm.aggregate.length > 0
                          ? _c(
                              "div",
                              { staticStyle: { "padding-left": "80px" } },
                              [
                                _c(
                                  "draggable",
                                  {
                                    attrs: { options: { group: "aggregate" } },
                                    model: {
                                      value: _vm.aggregate,
                                      callback: function($$v) {
                                        _vm.aggregate = $$v
                                      },
                                      expression: "aggregate"
                                    }
                                  },
                                  _vm._l(_vm.aggregate, function(item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        class: [
                                          "aggregateLink",
                                          _vm.aggregateItem.sortNum ==
                                          item.sortNum
                                            ? "aggregateLinkActive"
                                            : ""
                                        ],
                                        on: {
                                          click: function($event) {
                                            return _vm.openAggregateItem(
                                              item.sortNum
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            "链接" + _vm._s(item.sortNum + 1)
                                          )
                                        ]),
                                        !_vm.isDetail
                                          ? _c("i", {
                                              staticClass:
                                                "el-icon-close aggregateLinkCloseIcon",
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteSublevel(
                                                    item.sortNum,
                                                    "aggregate"
                                                  )
                                                }
                                              }
                                            })
                                          : _vm._e()
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.aggregate.length > 0
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "链接名称" } },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "230px" },
                                      attrs: {
                                        placeholder: "请输入链接名称",
                                        size: "small",
                                        disabled: _vm.isDetail
                                      },
                                      model: {
                                        value: _vm.aggregateItem.linkName,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.aggregateItem,
                                            "linkName",
                                            $$v
                                          )
                                        },
                                        expression: "aggregateItem.linkName"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticStyle: { display: "flex" } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { flex: "1" },
                                        attrs: { label: "链接展示图" }
                                      },
                                      [
                                        _vm.isDetail
                                          ? _c("div", [
                                              this.aggregateItem.linkShowImg &&
                                              this.aggregateItem.linkShowImg
                                                .videoUrl
                                                ? _c("div", [
                                                    this.aggregateItem.linkShowImg.videoUrl.url.includes(
                                                      ".svg"
                                                    )
                                                      ? _c("div", {
                                                          staticStyle: {
                                                            width: "100px",
                                                            "max-height":
                                                              "100px"
                                                          },
                                                          attrs: {
                                                            id:
                                                              "link" +
                                                              _vm.aggregateItem
                                                                .sortNum
                                                          }
                                                        })
                                                      : _c("img", {
                                                          staticStyle: {
                                                            width: "100px",
                                                            "max-height":
                                                              "100px"
                                                          },
                                                          attrs: {
                                                            src: this
                                                              .aggregateItem
                                                              .linkShowImg
                                                              .videoUrl.url,
                                                            alt: ""
                                                          }
                                                        })
                                                  ])
                                                : _vm._e()
                                            ])
                                          : _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  size: "small"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.openPicture(
                                                      3,
                                                      _vm.aggregateItem.sortNum
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("上传")]
                                            )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { flex: "1" },
                                        attrs: { label: "功能导航图" }
                                      },
                                      [
                                        _vm.isDetail
                                          ? _c("div", [
                                              this.aggregateItem
                                                .navigationImg &&
                                              this.aggregateItem.navigationImg
                                                .videoUrl
                                                ? _c("div", [
                                                    this.aggregateItem.navigationImg.videoUrl.url.includes(
                                                      ".svg"
                                                    )
                                                      ? _c("div", {
                                                          staticStyle: {
                                                            width: "100px",
                                                            "max-height":
                                                              "100px"
                                                          },
                                                          attrs: {
                                                            id:
                                                              "nav" +
                                                              _vm.aggregateItem
                                                                .sortNum
                                                          }
                                                        })
                                                      : _c("img", {
                                                          staticStyle: {
                                                            width: "100px",
                                                            "max-height":
                                                              "100px"
                                                          },
                                                          attrs: {
                                                            src: this
                                                              .aggregateItem
                                                              .navigationImg
                                                              .videoUrl.url,
                                                            alt: ""
                                                          }
                                                        })
                                                  ])
                                                : _vm._e()
                                            ])
                                          : _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  size: "small"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.openPicture(
                                                      4,
                                                      _vm.aggregateItem.sortNum
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("上传")]
                                            )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "链接类型" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          size: "small",
                                          placeholder: "请选择链接类型",
                                          disabled: _vm.isDetail
                                        },
                                        model: {
                                          value: _vm.aggregateItem.linkType,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.aggregateItem,
                                              "linkType",
                                              $$v
                                            )
                                          },
                                          expression: "aggregateItem.linkType"
                                        }
                                      },
                                      _vm._l(
                                        _vm.aggregateLinkTypeOptions,
                                        function(typeItem) {
                                          return _c("el-option", {
                                            key: typeItem.dictValue,
                                            attrs: {
                                              label: typeItem.dictLabel,
                                              value: typeItem.dictValue
                                            }
                                          })
                                        }
                                      ),
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm.aggregateItem.linkType === 1
                                  ? _c(
                                      "el-form-item",
                                      { attrs: { label: "appid" } },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "230px" },
                                          attrs: {
                                            size: "small",
                                            autocomplete: "off",
                                            maxlength: "200",
                                            disabled: _vm.isDetail,
                                            placeholder: "请输入appId"
                                          },
                                          model: {
                                            value: _vm.aggregateItem.appid,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.aggregateItem,
                                                "appid",
                                                $$v
                                              )
                                            },
                                            expression: "aggregateItem.appid"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "链接地址" } },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "450px" },
                                      attrs: {
                                        type: "textarea",
                                        disabled: _vm.isDetail,
                                        placeholder: "请输入链接地址"
                                      },
                                      model: {
                                        value: _vm.aggregateItem.linkUrl,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.aggregateItem,
                                            "linkUrl",
                                            $$v
                                          )
                                        },
                                        expression: "aggregateItem.linkUrl"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "跳转区域" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "请选择跳转区域",
                                          size: "small",
                                          disabled: _vm.isDetail
                                        },
                                        model: {
                                          value: _vm.aggregateItem.jumpLinkArea,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.aggregateItem,
                                              "jumpLinkArea",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "aggregateItem.jumpLinkArea"
                                        }
                                      },
                                      _vm._l(_vm.linkZoneOptions, function(
                                        zoneItem
                                      ) {
                                        return _c("el-option", {
                                          key: zoneItem.dictValue,
                                          attrs: {
                                            label: zoneItem.dictLabel,
                                            value: zoneItem.dictValue
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm.aggregateItem.jumpLinkArea == "1"
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "区域坐标",
                                          size: "small"
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            autocomplete: "off",
                                            maxlength: "200",
                                            placeholder: "请输入区域坐标",
                                            disabled: _vm.isDetail
                                          },
                                          model: {
                                            value:
                                              _vm.aggregateItem.areaCoordinate,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.aggregateItem,
                                                "areaCoordinate",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "aggregateItem.areaCoordinate"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "实名验证" } },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        attrs: { disabled: _vm.isDetail },
                                        model: {
                                          value:
                                            _vm.aggregateItem
                                              .realnameVerification,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.aggregateItem,
                                              "realnameVerification",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "aggregateItem.realnameVerification"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 0 } },
                                          [_vm._v("否")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [_vm._v("是")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm.addForm.activityType === 9 &&
                  _vm.addForm.collectCardType === 0
                    ? _c(
                        "div",
                        _vm._l(_vm.collectForm, function(item, index) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              _c("div", { staticClass: "title" }, [
                                _c("i", {
                                  staticClass: "el-icon-remove",
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteSublevel(
                                        index,
                                        "collectForm"
                                      )
                                    }
                                  }
                                }),
                                _c("div", { staticClass: "liftTitle" }, [
                                  _vm._v("基础卡片" + _vm._s(index + 1))
                                ])
                              ]),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "卡片名称" } },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "230px" },
                                        attrs: {
                                          placeholder: "请输入卡片名称",
                                          size: "small"
                                        },
                                        model: {
                                          value: item.cardName,
                                          callback: function($$v) {
                                            _vm.$set(item, "cardName", $$v)
                                          },
                                          expression: "item.cardName"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { flex: "1" },
                                      attrs: { label: "集卡要求" }
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "请选择集卡要求"
                                          },
                                          model: {
                                            value: item.cardRequirements,
                                            callback: function($$v) {
                                              _vm.$set(
                                                item,
                                                "cardRequirements",
                                                $$v
                                              )
                                            },
                                            expression: "item.cardRequirements"
                                          }
                                        },
                                        _vm._l(
                                          _vm.collectDemandTypeOptions,
                                          function(item) {
                                            return _c("el-option", {
                                              key: item.dictValue,
                                              attrs: {
                                                label: item.dictLabel,
                                                value: item.dictValue
                                              }
                                            })
                                          }
                                        ),
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { flex: "1" },
                                      attrs: { label: "集卡素材" }
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "small"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.openCollectPicture(
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("添加")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "权益设置" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "small" },
                                      on: {
                                        click: function($event) {
                                          return _vm.openEquity(
                                            _vm.addForm.activityType,
                                            index
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("设置")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm.addForm.activityType === 9 &&
                  _vm.addForm.collectCardType === 0
                    ? _c(
                        "el-button",
                        {
                          staticClass: "addLift",
                          attrs: { size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.addSublevel("collectForm")
                            }
                          }
                        },
                        [_vm._v("+ 添加基础卡片")]
                      )
                    : _vm._e(),
                  _vm.addForm.activityType === 9 &&
                  _vm.addForm.collectCardType === 0
                    ? _c(
                        "div",
                        _vm._l(_vm.highTaskForm, function(item, index) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              _c("div", { staticClass: "title" }, [
                                _c("i", {
                                  staticClass: "el-icon-remove",
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteSublevel(
                                        index,
                                        "highTaskForm"
                                      )
                                    }
                                  }
                                }),
                                _c("div", { staticClass: "liftTitle" }, [
                                  _vm._v("进阶任务" + _vm._s(index + 1))
                                ])
                              ]),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "任务名称" } },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "230px" },
                                        attrs: {
                                          placeholder: "请输入任务名称",
                                          size: "small"
                                        },
                                        model: {
                                          value: item.taskName,
                                          callback: function($$v) {
                                            _vm.$set(item, "taskName", $$v)
                                          },
                                          expression: "item.taskName"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { flex: "1" },
                                      attrs: { label: "任务类型" }
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "请选择任务类型"
                                          },
                                          model: {
                                            value: item.taskType,
                                            callback: function($$v) {
                                              _vm.$set(item, "taskType", $$v)
                                            },
                                            expression: "item.taskType"
                                          }
                                        },
                                        _vm._l(
                                          _vm.highTaskTypeOptions,
                                          function(item) {
                                            return _c("el-option", {
                                              key: item.dictValue,
                                              attrs: {
                                                label: item.dictLabel,
                                                value: item.dictValue
                                              }
                                            })
                                          }
                                        ),
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { flex: "1" },
                                      attrs: { label: "任务图片" }
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "small"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.openHighTaskPicture(
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("添加")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              item.taskType === 0 || item.taskType === 1
                                ? _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: { flex: "1" },
                                          attrs: { label: "初始状态" }
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticClass: "compareSelect",
                                              attrs: { placeholder: "请选择" },
                                              model: {
                                                value: item.startState,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "startState",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.startState"
                                              }
                                            },
                                            _vm._l(
                                              _vm.compareTypeOptions,
                                              function(intStatusItem) {
                                                return _c("el-option", {
                                                  key: intStatusItem.dictValue,
                                                  attrs: {
                                                    label:
                                                      intStatusItem.dictLabel,
                                                    value:
                                                      intStatusItem.dictValue
                                                  }
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                          _c("el-input", {
                                            staticClass: "compareInput",
                                            attrs: {
                                              placeholder: "",
                                              size: "small"
                                            },
                                            model: {
                                              value: item.startNumber,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  item,
                                                  "startNumber",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression: "item.startNumber"
                                            }
                                          }),
                                          _vm._v("个 ")
                                        ],
                                        1
                                      ),
                                      item.taskType === 0
                                        ? _c(
                                            "el-form-item",
                                            {
                                              staticStyle: { flex: "1" },
                                              attrs: { label: "达标数量" }
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticClass: "compareSelect",
                                                  attrs: {
                                                    placeholder: "请选择"
                                                  },
                                                  model: {
                                                    value: item.reachState,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "reachState",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.reachState"
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.compareTypeOptions,
                                                  function(standardStatusItem) {
                                                    return _c("el-option", {
                                                      key:
                                                        standardStatusItem.dictValue,
                                                      attrs: {
                                                        label:
                                                          standardStatusItem.dictLabel,
                                                        value:
                                                          standardStatusItem.dictValue
                                                      }
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                              _c("el-input", {
                                                staticClass: "compareInput",
                                                attrs: {
                                                  placeholder: "",
                                                  size: "small"
                                                },
                                                model: {
                                                  value: item.reachNumber,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "reachNumber",
                                                      _vm._n($$v)
                                                    )
                                                  },
                                                  expression: "item.reachNumber"
                                                }
                                              }),
                                              _vm._v("个 ")
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      item.taskType === 1
                                        ? _c(
                                            "el-form-item",
                                            {
                                              staticStyle: { flex: "1" },
                                              attrs: { label: "提升数量" }
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "compareInput",
                                                attrs: {
                                                  placeholder: "",
                                                  size: "small"
                                                },
                                                model: {
                                                  value: item.promoteNumber,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "promoteNumber",
                                                      _vm._n($$v)
                                                    )
                                                  },
                                                  expression:
                                                    "item.promoteNumber"
                                                }
                                              }),
                                              _vm._v("个 ")
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-form-item",
                                { attrs: { label: "参与次数" } },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      on: { input: _vm.joinNumChange },
                                      model: {
                                        value: item.isLimit,
                                        callback: function($$v) {
                                          _vm.$set(item, "isLimit", $$v)
                                        },
                                        expression: "item.isLimit"
                                      }
                                    },
                                    [
                                      _c("el-radio", { attrs: { label: 0 } }, [
                                        _vm._v("不限")
                                      ]),
                                      _c("el-radio", { attrs: { label: 1 } }, [
                                        _vm._v("限制")
                                      ])
                                    ],
                                    1
                                  ),
                                  item.isLimit === 1
                                    ? _c("el-input", {
                                        staticClass: "compareInput",
                                        attrs: {
                                          placeholder: "",
                                          size: "small"
                                        },
                                        model: {
                                          value: item.limitNumber,
                                          callback: function($$v) {
                                            _vm.$set(
                                              item,
                                              "limitNumber",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "item.limitNumber"
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v("次 ")
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "权益设置" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "small" },
                                      on: {
                                        click: function($event) {
                                          return _vm.openEquity(
                                            _vm.addForm.activityType,
                                            index,
                                            "isHigh"
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("设置")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm.promoteTaskDTOList.length > 0 &&
                  _vm.addForm.activityType === 35
                    ? _c(
                        "div",
                        _vm._l(_vm.promoteTaskDTOList, function(item, index) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              _c("div", { staticClass: "title" }, [
                                !_vm.isDetail
                                  ? _c("i", {
                                      staticClass: "el-icon-remove",
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteSublevel(
                                            index,
                                            "promoteTaskDTOList"
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                _c("div", { staticClass: "liftTitle" }, [
                                  _vm._v("签到任务 " + _vm._s(index + 1))
                                ])
                              ]),
                              _c(
                                "el-form-item",
                                { attrs: { label: "任务名称" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "200px" },
                                    attrs: {
                                      placeholder: "请输入任务名称",
                                      size: "small",
                                      disabled: _vm.isDetail
                                    },
                                    model: {
                                      value: item.taskName,
                                      callback: function($$v) {
                                        _vm.$set(item, "taskName", $$v)
                                      },
                                      expression: "item.taskName"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "签到天数",
                                    prop: "signInDays"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "80px" },
                                    attrs: {
                                      size: "small",
                                      placeholder: "请输入",
                                      disabled: _vm.isDetail
                                    },
                                    model: {
                                      value: item.signInDays,
                                      callback: function($$v) {
                                        _vm.$set(
                                          item,
                                          "signInDays",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "item.signInDays"
                                    }
                                  }),
                                  _vm._v(" 天 ")
                                ],
                                1
                              ),
                              _vm.isDetail
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "权益设置" } },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "small"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.openEquity(
                                                _vm.addForm.activityType,
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("点击查看")]
                                      )
                                    ],
                                    1
                                  )
                                : _c(
                                    "el-form-item",
                                    { attrs: { label: "权益设置" } },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "small"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.openEquity(
                                                _vm.addForm.activityType,
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("设置")]
                                      )
                                    ],
                                    1
                                  )
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm.addForm.activityType === 35 && !_vm.isDetail
                    ? _c(
                        "el-button",
                        {
                          staticClass: "addLift",
                          attrs: { size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.addSublevel("promoteTaskDTOList")
                            }
                          }
                        },
                        [_vm._v("+ 添加签到任务")]
                      )
                    : _vm._e(),
                  _vm.addForm.activityType === 9 &&
                  _vm.addForm.collectCardType === 0
                    ? _c(
                        "el-button",
                        {
                          staticClass: "addLift",
                          attrs: { size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.addSublevel("highTaskForm")
                            }
                          }
                        },
                        [_vm._v("+ 添加高阶任务")]
                      )
                    : _vm._e(),
                  _vm.addForm.activityType === 9 &&
                  _vm.addForm.collectCardType === 0
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "页面布局" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.openPageLayoutDiag }
                            },
                            [_vm._v("设置")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "报名设置", name: "3" } },
            [
              _c(
                "h4",
                {
                  staticStyle: { "margin-top": "10px", "margin-left": "10px" }
                },
                [_vm._v("报名设置")]
              ),
              _c(
                "el-form",
                {
                  staticStyle: { width: "700px" },
                  attrs: { "label-width": "150px" }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动报名" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择",
                            disabled: _vm.isDetail
                          },
                          model: {
                            value: _vm.applicationForm.isApply,
                            callback: function($$v) {
                              _vm.$set(_vm.applicationForm, "isApply", $$v)
                            },
                            expression: "applicationForm.isApply"
                          }
                        },
                        [
                          _c("el-option", { attrs: { label: "否", value: 0 } }),
                          _c("el-option", { attrs: { label: "是", value: 1 } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.applicationForm.isApply
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "报名按钮位置" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择",
                                disabled: _vm.isDetail
                              },
                              model: {
                                value: _vm.applicationForm.buttonType,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.applicationForm,
                                    "buttonType",
                                    $$v
                                  )
                                },
                                expression: "applicationForm.buttonType"
                              }
                            },
                            _vm._l(_vm.btnPositonOptions, function(item) {
                              return _c("el-option", {
                                key: item.dictValue,
                                attrs: {
                                  label: item.dictLabel,
                                  value: item.dictValue
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.applicationForm.isApply &&
                  _vm.applicationForm.buttonType == "1"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "按钮名称" } },
                        [
                          _c("el-input", {
                            staticClass: "enrollCommonInput",
                            attrs: {
                              placeholder: "请输入按钮名称",
                              disabled: _vm.isDetail
                            },
                            model: {
                              value: _vm.applicationForm.buttonName,
                              callback: function($$v) {
                                _vm.$set(_vm.applicationForm, "buttonName", $$v)
                              },
                              expression: "applicationForm.buttonName"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.applicationForm.isApply &&
                  _vm.applicationForm.buttonType == "1"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "按钮起始坐标" } },
                        [
                          _c("el-input", {
                            staticClass: "enrollCommonInput",
                            attrs: {
                              placeholder: "请输入按钮起始坐标",
                              disabled: _vm.isDetail
                            },
                            model: {
                              value: _vm.applicationForm.buttonPosition,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.applicationForm,
                                  "buttonPosition",
                                  $$v
                                )
                              },
                              expression: "applicationForm.buttonPosition"
                            }
                          }),
                          _c(
                            "el-checkbox",
                            {
                              staticStyle: { "margin-left": "20px" },
                              attrs: { disabled: _vm.isDetail },
                              model: {
                                value: _vm.fixPositionChecked,
                                callback: function($$v) {
                                  _vm.fixPositionChecked = $$v
                                },
                                expression: "fixPositionChecked"
                              }
                            },
                            [_vm._v("固定位置")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.applicationForm.isApply
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "资格审核" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择",
                                disabled: _vm.isDetail
                              },
                              model: {
                                value:
                                  _vm.applicationForm.isQualificationReview,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.applicationForm,
                                    "isQualificationReview",
                                    $$v
                                  )
                                },
                                expression:
                                  "applicationForm.isQualificationReview"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "否", value: 0 }
                              }),
                              _c("el-option", {
                                attrs: { label: "是", value: 1 }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.applicationForm.isApply &&
                  _vm.applicationForm.isQualificationReview == "1"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "资格要求" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择",
                                disabled: _vm.isDetail
                              },
                              model: {
                                value:
                                  _vm.applicationForm.qualificationRequirements,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.applicationForm,
                                    "qualificationRequirements",
                                    $$v
                                  )
                                },
                                expression:
                                  "applicationForm.qualificationRequirements"
                              }
                            },
                            _vm._l(_vm.qualificationOptions, function(item) {
                              return _c("el-option", {
                                key: item.dictValue,
                                attrs: {
                                  label: item.dictLabel,
                                  value: item.dictValue
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "页面推荐", name: "5" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                    "align-items": "center"
                  }
                },
                [
                  _c(
                    "h4",
                    {
                      staticStyle: {
                        "margin-top": "10px",
                        "margin-left": "10px"
                      }
                    },
                    [_vm._v("产品/活动推荐")]
                  ),
                  _c(
                    "div",
                    { staticStyle: { "margin-right": "30px" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100px" },
                          attrs: {
                            placeholder: "请选择",
                            size: "small",
                            disabled: _vm.isDetail
                          },
                          model: {
                            value: _vm.layoutStyle,
                            callback: function($$v) {
                              _vm.layoutStyle = $$v
                            },
                            expression: "layoutStyle"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "平铺", value: 1 }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _vm._l(_vm.sales, function(item, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c("div", { staticClass: "title" }, [
                      !_vm.isDetail
                        ? _c("i", {
                            staticClass: "el-icon-remove",
                            staticStyle: { "margin-left": "40px" },
                            on: {
                              click: function($event) {
                                return _vm.deleteSublevel(index, "sales")
                              }
                            }
                          })
                        : _vm._e(),
                      _c("div", { staticClass: "rightLiftTitle" }, [
                        _vm._v("推荐 " + _vm._s(index + 1))
                      ])
                    ]),
                    _c(
                      "el-form",
                      {
                        staticStyle: { width: "700px", "margin-top": "30px" },
                        attrs: { "label-width": "120px" }
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "名称" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "300px" },
                              attrs: {
                                size: "small",
                                placeholder: "请输入推荐产品或活动的名称",
                                disabled: _vm.isDetail
                              },
                              model: {
                                value: item.recommendName,
                                callback: function($$v) {
                                  _vm.$set(item, "recommendName", $$v)
                                },
                                expression: "item.recommendName"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "产品展示方式" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择",
                                  size: "small",
                                  disabled: _vm.isDetail
                                },
                                model: {
                                  value: item.displayStyle,
                                  callback: function($$v) {
                                    _vm.$set(item, "displayStyle", $$v)
                                  },
                                  expression: "item.displayStyle"
                                }
                              },
                              _vm._l(_vm.displayStyleOptions, function(item) {
                                return _c("el-option", {
                                  key: item.dictValue,
                                  attrs: {
                                    label: item.dictLabel,
                                    value: item.dictValue
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        item.displayStyle === 1
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "产品banner",
                                  disabled: _vm.isDetail
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { size: "small" },
                                    model: {
                                      value: item.bannerUuid,
                                      callback: function($$v) {
                                        _vm.$set(item, "bannerUuid", $$v)
                                      },
                                      expression: "item.bannerUuid"
                                    }
                                  },
                                  _vm._l(
                                    _vm.FinancialProductsBannerOptions,
                                    function(item) {
                                      return _c("el-option", {
                                        key: item.secUuid,
                                        attrs: {
                                          label: item.bannerName,
                                          value: item.secUuid
                                        }
                                      })
                                    }
                                  ),
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        item.displayStyle === 0
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "banner" } },
                              [
                                _vm.isDetail
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "small"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.openDetailPicture(
                                              2,
                                              index
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("点击查看")]
                                    )
                                  : _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "small"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.openProductPop(index)
                                          }
                                        }
                                      },
                                      [_vm._v("设置")]
                                    )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-form-item",
                          { attrs: { label: "跳转类型", prop: "linkType" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择跳转方式",
                                  size: "small",
                                  disabled: _vm.isDetail
                                },
                                model: {
                                  value: item.linkType,
                                  callback: function($$v) {
                                    _vm.$set(item, "linkType", $$v)
                                  },
                                  expression: "item.linkType"
                                }
                              },
                              _vm._l(_vm.recommendLinkTypeOptions, function(
                                typeItem
                              ) {
                                return _c("el-option", {
                                  key: typeItem.dictValue,
                                  attrs: {
                                    label: typeItem.dictLabel,
                                    value: typeItem.dictValue
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        item.linkType === "applet"
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "appid", prop: "appId" } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "300px" },
                                  attrs: {
                                    autocomplete: "off",
                                    maxlength: "200",
                                    disabled: _vm.isDetail,
                                    placeholder: "请输入appId",
                                    size: "small"
                                  },
                                  model: {
                                    value: item.appId,
                                    callback: function($$v) {
                                      _vm.$set(item, "appId", $$v)
                                    },
                                    expression: "item.appId"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-form-item",
                          { attrs: { label: "链接地址", prop: "linkUrl" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "450px" },
                              attrs: {
                                type: "textarea",
                                disabled: _vm.isDetail,
                                placeholder: "请输入链接地址",
                                size: "small"
                              },
                              model: {
                                value: item.linkUrl,
                                callback: function($$v) {
                                  _vm.$set(item, "linkUrl", $$v)
                                },
                                expression: "item.linkUrl"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              !_vm.isDetail
                ? _c(
                    "el-button",
                    {
                      staticClass: "addLift",
                      attrs: { size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.addSublevel("sales")
                        }
                      }
                    },
                    [_vm._v("+ 添加")]
                  )
                : _vm._e(),
              _c(
                "div",
                [
                  _c("span", { staticClass: "salesCheckLabel" }, [
                    _vm._v("更多推荐功能")
                  ]),
                  _c(
                    "el-checkbox",
                    {
                      staticStyle: { "margin-left": "20px" },
                      attrs: { disabled: _vm.isDetail },
                      on: { change: _vm.moreRecomendChange },
                      model: {
                        value: _vm.salesChecked,
                        callback: function($$v) {
                          _vm.salesChecked = $$v
                        },
                        expression: "salesChecked"
                      }
                    },
                    [_vm._v("启用")]
                  ),
                  _vm.salesChecked
                    ? _c(
                        "el-form",
                        {
                          ref: "moreRecomendForm",
                          staticStyle: { "margin-top": "22px" },
                          attrs: {
                            model: _vm.moreRecomendForm,
                            "label-width": "120px",
                            rules: _vm.moreRecomendFormRules
                          }
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "跳转类型", prop: "linkType" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择跳转方式",
                                    disabled: _vm.isDetail
                                  },
                                  model: {
                                    value: _vm.moreRecomendForm.linkType,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.moreRecomendForm,
                                        "linkType",
                                        $$v
                                      )
                                    },
                                    expression: "moreRecomendForm.linkType"
                                  }
                                },
                                _vm._l(_vm.recommendLinkTypeOptions, function(
                                  typeItem
                                ) {
                                  return _c("el-option", {
                                    key: typeItem.dictValue,
                                    attrs: {
                                      label: typeItem.dictLabel,
                                      value: typeItem.dictValue
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _vm.moreRecomendForm.linkType === "applet"
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "appid", prop: "appId" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "300px" },
                                    attrs: {
                                      autocomplete: "off",
                                      maxlength: "200",
                                      disabled: _vm.isDetail,
                                      placeholder: "请输入appId"
                                    },
                                    model: {
                                      value: _vm.moreRecomendForm.appId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.moreRecomendForm,
                                          "appId",
                                          $$v
                                        )
                                      },
                                      expression: "moreRecomendForm.appId"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-form-item",
                            { attrs: { label: "链接地址", prop: "linkUrl" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "450px" },
                                attrs: {
                                  type: "textarea",
                                  disabled: _vm.isDetail,
                                  placeholder: "请输入链接地址"
                                },
                                model: {
                                  value: _vm.moreRecomendForm.linkUrl,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.moreRecomendForm,
                                      "linkUrl",
                                      $$v
                                    )
                                  },
                                  expression: "moreRecomendForm.linkUrl"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            2
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "installTime",
          attrs: {
            width: "500px",
            title: "活动时间",
            visible: _vm.timeVisible,
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.timeVisible = $event
            },
            close: _vm.timeClose
          }
        },
        [
          !_vm.timeState
            ? [
                _vm.addForm.activityType === 8 || _vm.addForm.activityType === 3
                  ? _c(
                      "div",
                      [
                        _vm._v(" 活动时间 "),
                        _c("el-date-picker", {
                          staticStyle: { width: "150px" },
                          attrs: {
                            disabled: _vm.disabled,
                            "value-format": "yyyy-MM-dd",
                            type: "date",
                            placeholder: "活动开始日期"
                          },
                          model: {
                            value: _vm.addForm.applyStartTime,
                            callback: function($$v) {
                              _vm.$set(_vm.addForm, "applyStartTime", $$v)
                            },
                            expression: "addForm.applyStartTime"
                          }
                        }),
                        _vm._v(" -- "),
                        _c("el-date-picker", {
                          staticStyle: { width: "150px" },
                          attrs: {
                            disabled: _vm.disabled,
                            "value-format": "yyyy-MM-dd",
                            type: "date",
                            placeholder: "活动结束日期"
                          },
                          model: {
                            value: _vm.addForm.applyFinishTime,
                            callback: function($$v) {
                              _vm.$set(_vm.addForm, "applyFinishTime", $$v)
                            },
                            expression: "addForm.applyFinishTime"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.addForm.activityType !== 8 && _vm.addForm.activityType !== 3
                  ? _c(
                      "div",
                      [
                        _vm._v(" 报名时间 "),
                        _c("el-date-picker", {
                          staticStyle: { width: "150px" },
                          attrs: {
                            disabled: _vm.disabled,
                            "value-format": "yyyy-MM-dd",
                            type: "date",
                            placeholder: "报名开始日期"
                          },
                          model: {
                            value: _vm.addForm.applyStartTime,
                            callback: function($$v) {
                              _vm.$set(_vm.addForm, "applyStartTime", $$v)
                            },
                            expression: "addForm.applyStartTime"
                          }
                        }),
                        _vm._v(" -- "),
                        _c("el-date-picker", {
                          staticStyle: { width: "150px" },
                          attrs: {
                            disabled: _vm.disabled,
                            "value-format": "yyyy-MM-dd",
                            type: "date",
                            placeholder: "报名结束日期"
                          },
                          model: {
                            value: _vm.addForm.applyFinishTime,
                            callback: function($$v) {
                              _vm.$set(_vm.addForm, "applyFinishTime", $$v)
                            },
                            expression: "addForm.applyFinishTime"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.addForm.activityType !== 8 && _vm.addForm.activityType !== 3
                  ? _c(
                      "div",
                      [
                        _vm._v(" 任务时间 "),
                        _c("el-date-picker", {
                          staticStyle: { width: "150px" },
                          attrs: {
                            disabled: _vm.disabled,
                            "value-format": "yyyy-MM-dd",
                            type: "date",
                            placeholder: "达成开始日期"
                          },
                          model: {
                            value: _vm.addForm.reachStartTime,
                            callback: function($$v) {
                              _vm.$set(_vm.addForm, "reachStartTime", $$v)
                            },
                            expression: "addForm.reachStartTime"
                          }
                        }),
                        _vm._v(" -- "),
                        _c("el-date-picker", {
                          staticStyle: { width: "150px" },
                          attrs: {
                            disabled: _vm.disabled,
                            "value-format": "yyyy-MM-dd",
                            type: "date",
                            placeholder: "达成结束日期"
                          },
                          model: {
                            value: _vm.addForm.reachFinishTime,
                            callback: function($$v) {
                              _vm.$set(_vm.addForm, "reachFinishTime", $$v)
                            },
                            expression: "addForm.reachFinishTime"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.addForm.activityType !== 8 && _vm.addForm.activityType !== 3
                  ? _c(
                      "div",
                      [
                        _vm._v(" 领奖时间 "),
                        _c("el-date-picker", {
                          staticStyle: { width: "150px" },
                          attrs: {
                            disabled: _vm.disabled,
                            "value-format": "yyyy-MM-dd",
                            type: "date",
                            placeholder: "领奖开始日期"
                          },
                          model: {
                            value: _vm.addForm.getStartTime,
                            callback: function($$v) {
                              _vm.$set(_vm.addForm, "getStartTime", $$v)
                            },
                            expression: "addForm.getStartTime"
                          }
                        }),
                        _vm._v(" -- "),
                        _c("el-date-picker", {
                          staticStyle: { width: "150px" },
                          attrs: {
                            disabled: _vm.disabled,
                            "value-format": "yyyy-MM-dd",
                            type: "date",
                            placeholder: "领奖结束日期"
                          },
                          model: {
                            value: _vm.addForm.getFinishTime,
                            callback: function($$v) {
                              _vm.$set(_vm.addForm, "getFinishTime", $$v)
                            },
                            expression: "addForm.getFinishTime"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function($event) {
                            _vm.timeVisible = false
                          }
                        }
                      },
                      [_vm._v("取 消")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.setActivityTime(_vm.timeState)
                          }
                        }
                      },
                      [_vm._v("确 定")]
                    )
                  ],
                  1
                )
              ]
            : [
                _c(
                  "div",
                  [
                    _vm._v(" 活动时间 "),
                    _c("el-date-picker", {
                      attrs: {
                        disabled: _vm.disabled,
                        type: "daterange",
                        "value-format": "yyyy-MM-dd",
                        "range-separator": "至",
                        "start-placeholder": "活动开始日期",
                        "end-placeholder": "活动结束日期"
                      },
                      model: {
                        value: _vm.retentionFormTime,
                        callback: function($$v) {
                          _vm.retentionFormTime = $$v
                        },
                        expression: "retentionFormTime"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function($event) {
                            _vm.timeVisible = false
                          }
                        }
                      },
                      [_vm._v("取 消")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.setActivityTime(_vm.timeState)
                          }
                        }
                      },
                      [_vm._v("确 定")]
                    )
                  ],
                  1
                )
              ]
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "500px",
            title: "图片上传",
            visible: _vm.pictureVisible,
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.pictureVisible = $event
            },
            close: _vm.pictureClose
          }
        },
        [
          _c(
            "div",
            { staticStyle: { display: "flex", "align-items": "center" } },
            [
              _c("span", { staticStyle: { "margin-right": "20px" } }, [
                _vm._v("背景图上传:")
              ]),
              _vm.isDetail
                ? _c("div", [
                    _vm.file.length &&
                    _vm.file[0].videoUrl &&
                    _vm.file[0].videoUrl.url
                      ? _c("div", [
                          _vm.file[0].videoUrl.url.includes(".svg")
                            ? _c("div", {
                                staticStyle: {
                                  width: "100px",
                                  "max-height": "100px"
                                },
                                attrs: { id: "bgSvgId" }
                              })
                            : _c("img", {
                                staticStyle: {
                                  width: "100px",
                                  "max-height": "100px"
                                },
                                attrs: {
                                  src: _vm.file[0].videoUrl.url,
                                  alt: ""
                                }
                              })
                        ])
                      : _vm._e()
                  ])
                : _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        action: "''",
                        limit: 1,
                        "before-remove": _vm.beforeBgcRemove,
                        "on-exceed": _vm.handleExceed,
                        "file-list": _vm.file,
                        "http-request": _vm.handleBgcUploadPicture
                      }
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [_vm._v("点击上传")]
                      )
                    ],
                    1
                  )
            ],
            1
          ),
          _vm.pictureType === 1
            ? _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "margin-top": "30px"
                  }
                },
                [
                  _c("span", { staticStyle: { "margin-right": "20px" } }, [
                    _vm._v("轮播图上传:")
                  ]),
                  _vm.isDetail
                    ? _c("div", [
                        _vm.files.length
                          ? _c(
                              "div",
                              _vm._l(_vm.files, function(item, index) {
                                return _c("div", { key: index }, [
                                  item.bannerUrl && item.bannerUrl.url
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "100px",
                                          "max-height": "100px"
                                        },
                                        attrs: {
                                          src: item.bannerUrl.url,
                                          alt: ""
                                        }
                                      })
                                    : _vm._e()
                                ])
                              }),
                              0
                            )
                          : _vm._e()
                      ])
                    : _c(
                        "el-upload",
                        {
                          staticClass: "upload-demo",
                          attrs: {
                            action: "''",
                            multiple: "",
                            limit: 100,
                            "file-list": _vm.files,
                            "http-request": _vm.handleCarouselSuccess,
                            "on-exceed": _vm.handleCarouselExceed,
                            "on-remove": _vm.handleCarouselRemove
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "file",
                                fn: function(ref) {
                                  var file = ref.file
                                  return [
                                    _c("div", { staticClass: "file-item" }, [
                                      _c("i", {
                                        staticClass: "el-icon-document"
                                      }),
                                      _c("span", { staticClass: "itemText" }, [
                                        _vm._v(_vm._s(file.name))
                                      ]),
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "el-upload-list__item-status-label"
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-upload-success el-icon-circle-check"
                                          })
                                        ]
                                      ),
                                      _c("i", {
                                        staticClass:
                                          "el-icon-close carouseCloseIcon",
                                        on: {
                                          click: function($event) {
                                            return _vm.handleCarouselRemove(
                                              file
                                            )
                                          }
                                        }
                                      }),
                                      _c("i", {
                                        staticClass: "el-icon-setting",
                                        on: {
                                          click: function($event) {
                                            return _vm.openLinkPop(file)
                                          }
                                        }
                                      })
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            467296179
                          )
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v("点击上传")]
                          )
                        ],
                        1
                      )
                ],
                1
              )
            : _vm._e(),
          _vm.isDetail
            ? _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.pictureVisible = false
                        }
                      }
                    },
                    [_vm._v("关 闭")]
                  )
                ],
                1
              )
            : _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.pictureVisible = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.pictureAddLoading
                      },
                      on: { click: _vm.uploadPicture }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "equity-dialog",
          attrs: {
            width: "800px",
            title: "权益设置",
            visible: _vm.equityVisible,
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.equityVisible = $event
            },
            close: _vm.handleEquityBeforeClose
          }
        },
        [
          _c(
            "el-tabs",
            {
              staticClass: "tabControl tabControl2",
              staticStyle: { height: "100%" },
              attrs: { "tab-position": "left", stretch: "" },
              model: {
                value: _vm.equityTabsActiveName,
                callback: function($$v) {
                  _vm.equityTabsActiveName = $$v
                },
                expression: "equityTabsActiveName"
              }
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "达标权益", name: "1" } },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        "label-width": "120px",
                        model: _vm.promoteEquityForm
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "权益类型" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                disabled: _vm.isDetail,
                                placeholder: "请选择权益类型"
                              },
                              model: {
                                value: _vm.promoteEquityForm.rightsType,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.promoteEquityForm,
                                    "rightsType",
                                    $$v
                                  )
                                },
                                expression: "promoteEquityForm.rightsType"
                              }
                            },
                            _vm._l(_vm.rightsTypeOptions, function(item) {
                              return _c("el-option", {
                                key: item.dictValue,
                                attrs: {
                                  label: item.dictLabel,
                                  value: item.dictValue
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.promoteEquityForm.rightsType === 1,
                              expression: "promoteEquityForm.rightsType === 1"
                            }
                          ]
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "抽奖配置" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: _vm.isDetail,
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.promoteEquityForm.extractUuid,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.promoteEquityForm,
                                        "extractUuid",
                                        $$v
                                      )
                                    },
                                    expression: "promoteEquityForm.extractUuid"
                                  }
                                },
                                _vm._l(_vm.promoteDrawOptions, function(
                                  item,
                                  index
                                ) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.label,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.promoteEquityForm.rightsType === 0 ||
                                _vm.promoteEquityForm.rightsType === 3,
                              expression:
                                "promoteEquityForm.rightsType===0 || promoteEquityForm.rightsType===3"
                            }
                          ]
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "领取方式" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: _vm.isDetail,
                                    placeholder: "请选择领取方式"
                                  },
                                  model: {
                                    value: _vm.promoteEquityForm.receiveMethod,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.promoteEquityForm,
                                        "receiveMethod",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "promoteEquityForm.receiveMethod"
                                  }
                                },
                                _vm._l(_vm.receiveMethodOptions, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.dictValue,
                                    attrs: {
                                      label: item.dictLabel,
                                      value: item.dictValue
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "选择礼品" } },
                            [
                              _vm.isDetail
                                ? _c(
                                    "el-select",
                                    {
                                      ref: "giftSelect",
                                      class: [
                                        "giftSel",
                                        _vm.isSelecting ? "giftTranslate" : ""
                                      ],
                                      attrs: {
                                        disabled: _vm.isDetail,
                                        multiple: "",
                                        placeholder: "请选择礼品"
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.giftChang(
                                            "promoteEquityForm"
                                          )
                                        },
                                        focus: _vm.giftFocus
                                      },
                                      model: {
                                        value:
                                          _vm.promoteEquityForm.rightsGiftDTOs,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.promoteEquityForm,
                                            "rightsGiftDTOs",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "promoteEquityForm.rightsGiftDTOs"
                                      }
                                    },
                                    _vm._l(_vm.promoteOptions, function(item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: { label: item.name, value: item }
                                      })
                                    }),
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      _c("selectComponent", {
                                        attrs: {
                                          value:
                                            _vm.promoteEquityForm.rightsGiftDTOs
                                        },
                                        on: { selected: _vm.setSelectValue }
                                      })
                                    ],
                                    1
                                  ),
                              _c(
                                "el-table",
                                {
                                  staticStyle: {
                                    width: "90%",
                                    "margin-top": "20px"
                                  },
                                  attrs: {
                                    data: _vm.promoteEquityForm.rightsGiftDTOs,
                                    border: "",
                                    "max-height": "240px",
                                    size: "mini",
                                    "header-cell-style": {
                                      background: "#BEBEBE",
                                      color: "rgba(255, 255, 255, 1);"
                                    }
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: { prop: "name", label: "礼品名称" }
                                  }),
                                  _c("el-table-column", {
                                    attrs: { prop: "type", label: "礼品类型" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._v(
                                              _vm._s(
                                                _vm.giftTypePreview(
                                                  scope.row.type
                                                )
                                              )
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  }),
                                  _c("el-table-column", {
                                    attrs: { prop: "type", label: "渠道来源" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._v(
                                              _vm._s(
                                                _vm.channelTypePreview(
                                                  scope.row.giftId
                                                )
                                              )
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  }),
                                  _c("el-table-column", {
                                    attrs: { prop: "inventory", label: "库存" }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "每日限量",
                                      prop: "giftDayNumber",
                                      width: "100"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: _vm.isDetail,
                                                placeholder: "请输入",
                                                size: "mini"
                                              },
                                              model: {
                                                value: scope.row.giftDayNumber,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "giftDayNumber",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression:
                                                  "scope.row.giftDayNumber"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "每月限量",
                                      prop: "giftMonthNumber",
                                      width: "100"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: _vm.isDetail,
                                                placeholder: "请输入",
                                                size: "mini"
                                              },
                                              model: {
                                                value:
                                                  scope.row.giftMonthNumber,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "giftMonthNumber",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression:
                                                  "scope.row.giftMonthNumber"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "总限量",
                                      prop: "giftNumber",
                                      width: "100"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var row = ref.row
                                          return [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: _vm.isDetail,
                                                placeholder: "请输入",
                                                size: "mini"
                                              },
                                              model: {
                                                value: row.giftNumber,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "giftNumber",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression: "row.giftNumber"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  }),
                                  _vm.promoteEquityForm.receiveMethod === 2
                                    ? _c("el-table-column", {
                                        attrs: {
                                          label: "概率",
                                          prop: "probability",
                                          width: "100"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var row = ref.row
                                                return [
                                                  _c("el-input", {
                                                    attrs: {
                                                      disabled: _vm.isDetail,
                                                      placeholder: "请输入",
                                                      size: "mini"
                                                    },
                                                    model: {
                                                      value: row.probability,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          row,
                                                          "probability",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "row.probability"
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          3938810035
                                        )
                                      })
                                    : _vm._e(),
                                  !_vm.isDetail
                                    ? _c("el-table-column", {
                                        attrs: {
                                          label: "",
                                          width: "40",
                                          align: "center"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var $index = ref.$index
                                                return [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-delete delRightsIcon",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.delRightsGift(
                                                          $index
                                                        )
                                                      }
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          854563689
                                        )
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.promoteEquityForm.rightsType === 2,
                              expression: "promoteEquityForm.rightsType===2"
                            }
                          ]
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "领取方式" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: _vm.isDetail,
                                    placeholder: "请选择领取方式"
                                  },
                                  model: {
                                    value: _vm.promoteEquityForm.receiveMethod,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.promoteEquityForm,
                                        "receiveMethod",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "promoteEquityForm.receiveMethod"
                                  }
                                },
                                _vm._l(_vm.receiveMethodOptions, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.dictValue,
                                    attrs: {
                                      label: item.dictLabel,
                                      value: item.dictValue
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _vm.addForm.activityType === 9
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "选择礼品" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "small" },
                                      on: { click: _vm.addCollectGift }
                                    },
                                    [_vm._v("添加")]
                                  ),
                                  _c(
                                    "el-table",
                                    {
                                      staticStyle: {
                                        width: "90%",
                                        "margin-top": "20px"
                                      },
                                      attrs: {
                                        data:
                                          _vm.promoteEquityForm.rightsGiftDTOs,
                                        border: "",
                                        "max-height": "240px",
                                        size: "mini",
                                        "header-cell-style": {
                                          background: "#BEBEBE",
                                          color: "rgba(255, 255, 255, 1);"
                                        }
                                      }
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "礼品类型",
                                          prop: "giftType",
                                          width: "130"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var row = ref.row
                                                return [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      attrs: {
                                                        placeholder:
                                                          "请选择礼品类型",
                                                        disabled: _vm.isDetail,
                                                        size: "mini"
                                                      },
                                                      model: {
                                                        value: row.giftType,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            row,
                                                            "giftType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "row.giftType"
                                                      }
                                                    },
                                                    _vm._l(
                                                      _vm.integrateTypeOptions,
                                                      function(integrateItem) {
                                                        return _c("el-option", {
                                                          key:
                                                            "" +
                                                            integrateItem.dictValue,
                                                          attrs: {
                                                            label:
                                                              integrateItem.dictLabel,
                                                            value:
                                                              integrateItem.dictValue
                                                          }
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          501561776
                                        )
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "赠送积分数",
                                          prop: "giftPoints",
                                          width: "100"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var row = ref.row
                                                return [
                                                  _c("el-input", {
                                                    attrs: {
                                                      disabled: _vm.isDetail,
                                                      placeholder: "请输入",
                                                      size: "mini"
                                                    },
                                                    model: {
                                                      value: row.giftPoints,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          row,
                                                          "giftPoints",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "row.giftPoints"
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          3247149293
                                        )
                                      }),
                                      _vm.promoteEquityForm.receiveMethod === 2
                                        ? _c("el-table-column", {
                                            attrs: {
                                              label: "概率",
                                              prop: "probability",
                                              width: "100"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _c("el-input", {
                                                        attrs: {
                                                          disabled:
                                                            _vm.isDetail,
                                                          placeholder: "请输入",
                                                          size: "mini"
                                                        },
                                                        model: {
                                                          value:
                                                            row.probability,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              row,
                                                              "probability",
                                                              typeof $$v ===
                                                                "string"
                                                                ? $$v.trim()
                                                                : $$v
                                                            )
                                                          },
                                                          expression:
                                                            "row.probability"
                                                        }
                                                      })
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              1532478627
                                            )
                                          })
                                        : _vm._e(),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "每月限量",
                                          prop: "giftMonthNumber",
                                          width: "100"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var row = ref.row
                                                return [
                                                  _c("el-input", {
                                                    attrs: {
                                                      disabled: _vm.isDetail,
                                                      placeholder: "请输入",
                                                      size: "mini"
                                                    },
                                                    model: {
                                                      value:
                                                        row.giftMonthNumber,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          row,
                                                          "giftMonthNumber",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "row.giftMonthNumber"
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          3335927809
                                        )
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "总限量",
                                          prop: "giftNumber",
                                          width: "100"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var row = ref.row
                                                return [
                                                  _c("el-input", {
                                                    attrs: {
                                                      disabled: _vm.isDetail,
                                                      placeholder: "请输入",
                                                      size: "mini"
                                                    },
                                                    model: {
                                                      value: row.giftNumber,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          row,
                                                          "giftNumber",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "row.giftNumber"
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          3612207825
                                        )
                                      }),
                                      _c("el-table-column", {
                                        attrs: { label: "操作" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var row = ref.row
                                                var $index = ref.$index
                                                return [
                                                  _c(
                                                    "el-popconfirm",
                                                    {
                                                      attrs: {
                                                        "confirm-button-text":
                                                          "好的",
                                                        "cancel-button-text":
                                                          "取消",
                                                        icon: "el-icon-delete",
                                                        "icon-color": "red",
                                                        title:
                                                          "您确认要删除这条数据吗?"
                                                      },
                                                      on: {
                                                        confirm: function(
                                                          $event
                                                        ) {
                                                          return _vm.handleDeleteCollectGift(
                                                            row,
                                                            $index
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          staticClass:
                                                            "text-red",
                                                          staticStyle: {
                                                            color: "red"
                                                          },
                                                          attrs: {
                                                            slot: "reference",
                                                            size: "mini",
                                                            type: "text",
                                                            circle: ""
                                                          },
                                                          slot: "reference"
                                                        },
                                                        [_vm._v(" 删除 ")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          4045823963
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "权益次数" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                disabled: _vm.isDetail,
                                placeholder: "请选择权益次数"
                              },
                              model: {
                                value: _vm.promoteEquityForm.rightsNumber,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.promoteEquityForm,
                                    "rightsNumber",
                                    $$v
                                  )
                                },
                                expression: "promoteEquityForm.rightsNumber"
                              }
                            },
                            _vm._l(_vm.rightsNumberOptions, function(item) {
                              return _c("el-option", {
                                key: item.dictValue,
                                attrs: {
                                  label: item.dictLabel,
                                  value: item.dictValue
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.promoteEquityForm.rightsNumber === 2,
                              expression: "promoteEquityForm.rightsNumber === 2"
                            }
                          ],
                          attrs: { label: "每月领取时间" }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                disabled: _vm.isDetail,
                                placeholder: "请选择时间",
                                clearable: ""
                              },
                              model: {
                                value: _vm.promoteEquityForm.receiveMonthDate,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.promoteEquityForm,
                                    "receiveMonthDate",
                                    $$v
                                  )
                                },
                                expression: "promoteEquityForm.receiveMonthDate"
                              }
                            },
                            _vm._l(31, function(item) {
                              return _c("el-option", {
                                key: item,
                                attrs: { label: item + "号", value: item }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.promoteEquityForm.rightsNumber === 1,
                              expression: "promoteEquityForm.rightsNumber === 1"
                            }
                          ],
                          attrs: { label: "每周领取时间" }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                disabled: _vm.isDetail,
                                placeholder: "请选择时间",
                                clearable: ""
                              },
                              model: {
                                value: _vm.promoteEquityForm.receiveWeekDate,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.promoteEquityForm,
                                    "receiveWeekDate",
                                    $$v
                                  )
                                },
                                expression: "promoteEquityForm.receiveWeekDate"
                              }
                            },
                            _vm._l(_vm.dayOptions, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.promoteEquityForm.rightsNumber,
                              expression: "promoteEquityForm.rightsNumber"
                            }
                          ],
                          attrs: { label: "当日领取时间" }
                        },
                        [
                          _c("el-time-picker", {
                            attrs: {
                              disabled: _vm.isDetail,
                              placeholder: "选择开始时间",
                              "value-format": "HH:mm:ss"
                            },
                            model: {
                              value: _vm.promoteEquityForm.startTime,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.promoteEquityForm,
                                  "startTime",
                                  $$v
                                )
                              },
                              expression: "promoteEquityForm.startTime"
                            }
                          }),
                          _vm._v(" -- "),
                          _c("el-time-picker", {
                            attrs: {
                              disabled: _vm.isDetail,
                              placeholder: "选择结束时间",
                              "value-format": "HH:mm:ss"
                            },
                            model: {
                              value: _vm.promoteEquityForm.finishTime,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.promoteEquityForm,
                                  "finishTime",
                                  $$v
                                )
                              },
                              expression: "promoteEquityForm.finishTime"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.promoteEquityForm.rightsNumber === 0,
                              expression: "promoteEquityForm.rightsNumber === 0"
                            }
                          ],
                          attrs: { label: "领取时间" }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100px" },
                              attrs: {
                                disabled: _vm.isDetail,
                                placeholder: "请选择",
                                clearable: ""
                              },
                              model: {
                                value: _vm.promoteEquityForm.startDateOption,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.promoteEquityForm,
                                    "startDateOption",
                                    $$v
                                  )
                                },
                                expression: "promoteEquityForm.startDateOption"
                              }
                            },
                            _vm._l(_vm.startDateOption, function(item) {
                              return _c("el-option", {
                                key: item.dictValue,
                                attrs: {
                                  label: item.dictLabel,
                                  value: item.dictValue
                                }
                              })
                            }),
                            1
                          ),
                          _c("el-time-picker", {
                            staticStyle: { width: "120px" },
                            attrs: {
                              disabled: _vm.isDetail,
                              placeholder: "开始时间",
                              "value-format": "HH:mm:ss"
                            },
                            model: {
                              value: _vm.promoteEquityForm.startTime,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.promoteEquityForm,
                                  "startTime",
                                  $$v
                                )
                              },
                              expression: "promoteEquityForm.startTime"
                            }
                          }),
                          _vm._v(" -- "),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100px" },
                              attrs: {
                                disabled: _vm.isDetail,
                                placeholder: "请选择",
                                clearable: ""
                              },
                              model: {
                                value: _vm.promoteEquityForm.endDateOption,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.promoteEquityForm,
                                    "endDateOption",
                                    $$v
                                  )
                                },
                                expression: "promoteEquityForm.endDateOption"
                              }
                            },
                            _vm._l(_vm.endDateOption, function(item) {
                              return _c("el-option", {
                                key: item.dictValue,
                                attrs: {
                                  label: item.dictLabel,
                                  value: item.dictValue
                                }
                              })
                            }),
                            1
                          ),
                          _c("el-time-picker", {
                            staticStyle: { width: "120px" },
                            attrs: {
                              disabled: _vm.isDetail,
                              placeholder: "结束时间",
                              "value-format": "HH:mm:ss"
                            },
                            model: {
                              value: _vm.promoteEquityForm.finishTime,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.promoteEquityForm,
                                  "finishTime",
                                  $$v
                                )
                              },
                              expression: "promoteEquityForm.finishTime"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "膨胀权益", name: "2" } },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        "label-width": "80px",
                        model: _vm.swellEquityForm
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "膨胀方式" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                disabled: _vm.isDetail,
                                placeholder: "请选择膨胀方式"
                              },
                              model: {
                                value: _vm.swellEquityForm.expandType,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.swellEquityForm,
                                    "expandType",
                                    $$v
                                  )
                                },
                                expression: "swellEquityForm.expandType"
                              }
                            },
                            _vm._l(_vm.expandTypeOptions, function(item) {
                              return _c("el-option", {
                                key: item.dictValue,
                                attrs: {
                                  label: item.dictLabel,
                                  value: item.dictValue
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "权益类型" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                disabled: _vm.isDetail,
                                placeholder: "请选择权益类型"
                              },
                              model: {
                                value: _vm.swellEquityForm.rightsType,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.swellEquityForm,
                                    "rightsType",
                                    $$v
                                  )
                                },
                                expression: "swellEquityForm.rightsType"
                              }
                            },
                            _vm._l(_vm.rightsTypeOptions, function(item) {
                              return _c("el-option", {
                                key: item.dictValue,
                                attrs: {
                                  label: item.dictLabel,
                                  value: item.dictValue
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.swellEquityForm.rightsType === 1,
                              expression: "swellEquityForm.rightsType === 1"
                            }
                          ]
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "抽奖配置" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: _vm.isDetail,
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.swellEquityForm.extractUuid,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.swellEquityForm,
                                        "extractUuid",
                                        $$v
                                      )
                                    },
                                    expression: "swellEquityForm.extractUuid"
                                  }
                                },
                                _vm._l(_vm.swellDrawOptions, function(
                                  item,
                                  index
                                ) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.label,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.swellEquityForm.rightsType === 0,
                              expression: "swellEquityForm.rightsType === 0"
                            }
                          ]
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "领取方式" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: _vm.isDetail,
                                    placeholder: "请选择领取方式"
                                  },
                                  model: {
                                    value: _vm.swellEquityForm.receiveMethod,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.swellEquityForm,
                                        "receiveMethod",
                                        $$v
                                      )
                                    },
                                    expression: "swellEquityForm.receiveMethod"
                                  }
                                },
                                _vm._l(_vm.receiveMethodOptions, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.dictValue,
                                    attrs: {
                                      label: item.dictLabel,
                                      value: item.dictValue
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "选择礼品" } },
                            [
                              _vm.isDetail
                                ? _c(
                                    "el-select",
                                    {
                                      ref: "giftSelect",
                                      class: [
                                        "giftSel",
                                        _vm.isSelecting ? "giftTranslate" : ""
                                      ],
                                      attrs: {
                                        disabled: _vm.isDetail,
                                        multiple: "",
                                        placeholder: "请选择礼品"
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.giftChang(
                                            "swellEquityForm"
                                          )
                                        },
                                        focus: _vm.giftFocus
                                      },
                                      model: {
                                        value:
                                          _vm.swellEquityForm.rightsGiftDTOs,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.swellEquityForm,
                                            "rightsGiftDTOs",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "swellEquityForm.rightsGiftDTOs"
                                      }
                                    },
                                    _vm._l(_vm.promoteOptions, function(item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: { label: item.name, value: item }
                                      })
                                    }),
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      _c("selectComponent", {
                                        attrs: {
                                          value:
                                            _vm.swellEquityForm.rightsGiftDTOs
                                        },
                                        on: { selected: _vm.setSelectValue }
                                      })
                                    ],
                                    1
                                  ),
                              _c(
                                "el-table",
                                {
                                  staticStyle: {
                                    width: "90%",
                                    "margin-top": "20px"
                                  },
                                  attrs: {
                                    data: _vm.swellEquityForm.rightsGiftDTOs,
                                    border: "",
                                    "max-height": "240px",
                                    size: "mini",
                                    "header-cell-style": {
                                      background: "#BEBEBE",
                                      color: "rgba(255, 255, 255, 1);"
                                    }
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: { prop: "name", label: "礼品名称" }
                                  }),
                                  _c("el-table-column", {
                                    attrs: { prop: "type", label: "礼品类型" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._v(
                                              _vm._s(
                                                _vm.giftTypePreview(
                                                  scope.row.type
                                                )
                                              )
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  }),
                                  _c("el-table-column", {
                                    attrs: { prop: "type", label: "渠道来源" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._v(
                                              _vm._s(
                                                _vm.channelTypePreview(
                                                  scope.row.giftId
                                                )
                                              )
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  }),
                                  _c("el-table-column", {
                                    attrs: { prop: "inventory", label: "库存" }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "每日限量",
                                      prop: "giftDayNumber",
                                      width: "100"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: _vm.isDetail,
                                                placeholder: "请输入",
                                                size: "mini"
                                              },
                                              model: {
                                                value: scope.row.giftDayNumber,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "giftDayNumber",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression:
                                                  "scope.row.giftDayNumber"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "每月限量",
                                      prop: "giftMonthNumber",
                                      width: "100"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: _vm.isDetail,
                                                placeholder: "请输入",
                                                size: "mini"
                                              },
                                              model: {
                                                value:
                                                  scope.row.giftMonthNumber,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "giftMonthNumber",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression:
                                                  "scope.row.giftMonthNumber"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "总限量",
                                      prop: "giftNumber",
                                      width: "100"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var row = ref.row
                                          return [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: _vm.isDetail,
                                                placeholder: "请输入",
                                                size: "mini"
                                              },
                                              model: {
                                                value: row.giftNumber,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "giftNumber",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression: "row.giftNumber"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  }),
                                  _vm.swellEquityForm.receiveMethod === 2
                                    ? _c("el-table-column", {
                                        attrs: {
                                          label: "概率",
                                          prop: "probability",
                                          width: "100"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var row = ref.row
                                                return [
                                                  _c("el-input", {
                                                    attrs: {
                                                      disabled: _vm.isDetail,
                                                      placeholder: "请输入",
                                                      size: "mini"
                                                    },
                                                    model: {
                                                      value: row.probability,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          row,
                                                          "probability",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "row.probability"
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          3938810035
                                        )
                                      })
                                    : _vm._e(),
                                  !_vm.isDetail
                                    ? _c("el-table-column", {
                                        attrs: {
                                          label: "",
                                          width: "40",
                                          align: "center"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var $index = ref.$index
                                                return [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-delete delRightsIcon",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.delRightsGift(
                                                          $index
                                                        )
                                                      }
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          854563689
                                        )
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.swellEquityForm.expandType === 1,
                              expression: "swellEquityForm.expandType===1"
                            }
                          ],
                          attrs: { label: "助力设置" }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                disabled: _vm.isDetail,
                                placeholder: "请选择助力配置"
                              },
                              model: {
                                value: _vm.swellEquityForm.collaUuid,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.swellEquityForm,
                                    "collaUuid",
                                    $$v
                                  )
                                },
                                expression: "swellEquityForm.collaUuid"
                              }
                            },
                            _vm._l(_vm.collaUUIDOptions, function(item) {
                              return _c("el-option", {
                                key: item.secUuid,
                                attrs: {
                                  label: item.collaName,
                                  value: item.secUuid
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.isDetail
            ? _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.equityVisible = false
                        }
                      }
                    },
                    [_vm._v("关 闭")]
                  )
                ],
                1
              )
            : _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.equityVisible = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.addEquity }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "installTime",
          attrs: {
            width: "700px",
            title: "标签选择",
            visible: _vm.tagSelectorVisible,
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.tagSelectorVisible = $event
            }
          }
        },
        [
          _c(
            "el-checkbox-group",
            {
              model: {
                value: _vm.tagSelector1,
                callback: function($$v) {
                  _vm.tagSelector1 = $$v
                },
                expression: "tagSelector1"
              }
            },
            [
              _c("el-checkbox", { attrs: { label: "复选框1" } }),
              _c("el-checkbox", { attrs: { label: "复选框2" } }),
              _c("el-checkbox", { attrs: { label: "复选框3" } }),
              _c("el-checkbox", { attrs: { label: "复选框4" } })
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.tagSelectorVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.tagSelectorVisible = false
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "installTime",
          attrs: {
            width: "700px",
            title: "预览",
            visible: _vm.previewVisible,
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.previewVisible = $event
            },
            close: _vm.closePreview
          }
        },
        [
          _c(
            "el-form",
            {
              staticStyle: { width: "600px" },
              attrs: { "label-width": "160px" }
            },
            [
              _vm.disabled
                ? [
                    _vm.addForm.videoUploadInfoDTO &&
                    _vm.addForm.videoUploadInfoDTO.videoUrl
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "背景图URL:" } },
                          [
                            [
                              _c("img", {
                                staticStyle: { width: "100%", height: "200px" },
                                attrs: {
                                  src:
                                    Object.prototype.toString.call(
                                      _vm.addForm.videoUploadInfoDTO.videoUrl
                                    ) === "[object Object]"
                                      ? _vm.addForm.videoUploadInfoDTO.videoUrl
                                          .url
                                      : JSON.parse(
                                          _vm.addForm.videoUploadInfoDTO
                                            .videoUrl
                                        ).url,
                                  alt: ""
                                }
                              })
                            ]
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm.addForm.bannerPCDTOS && _vm.addForm.bannerPCDTOS.length
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "轮播图URL集合:" } },
                          [
                            _c(
                              "el-carousel",
                              { attrs: { height: "200px" } },
                              _vm._l(_vm.addForm.bannerPCDTOS, function(
                                item,
                                index
                              ) {
                                return _c("el-carousel-item", { key: index }, [
                                  _c("img", {
                                    staticStyle: {
                                      width: "100%",
                                      height: "100%"
                                    },
                                    attrs: { src: item.bannerUrl.url, alt: "" }
                                  })
                                ])
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                : [
                    _c("el-form-item", { attrs: { label: "活动规则:" } }, [
                      _vm._v(
                        " " + _vm._s(_vm.addForm.activityRuleExplain) + " "
                      )
                    ])
                  ]
            ],
            2
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.previewVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.previewVisible = false
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.isDetail
        ? _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.beforeDialog } }, [
                _vm._v("关 闭")
              ])
            ],
            1
          )
        : _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.beforeDialog } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btnSaveLoading },
                  on: { click: _vm.handlesave }
                },
                [_vm._v("保 存")]
              )
            ],
            1
          ),
      _vm.collectPictureDialog
        ? _c("CollectPictureDialog", {
            attrs: {
              row: _vm.collectItemRow,
              visible: _vm.collectPictureDialog
            },
            on: {
              "update:visible": function($event) {
                _vm.collectPictureDialog = $event
              },
              ok: _vm.collectPictureSuccess
            }
          })
        : _vm._e(),
      _vm.highTaskPictureVisible
        ? _c("HighTaskPictureUpload", {
            attrs: {
              row: _vm.highTaskItemRow,
              visible: _vm.highTaskPictureVisible
            },
            on: {
              "update:visible": function($event) {
                _vm.highTaskPictureVisible = $event
              },
              ok: _vm.highTaskPictureSuccess
            }
          })
        : _vm._e(),
      _vm.pageLayoutVisible
        ? _c("PageLayoutDialog", {
            attrs: { visible: _vm.pageLayoutVisible, row: _vm.pageLayoutRow },
            on: {
              "update:visible": function($event) {
                _vm.pageLayoutVisible = $event
              },
              ok: _vm.setPageLayoutSuccess
            }
          })
        : _vm._e(),
      _vm.pictureLinkVisible
        ? _c("PictureLinkDialog", {
            attrs: { visible: _vm.pictureLinkVisible, row: _vm.pictureLinkRow },
            on: {
              "update:visible": function($event) {
                _vm.pictureLinkVisible = $event
              },
              ok: _vm.setPictureLinkSuccess
            }
          })
        : _vm._e(),
      _vm.productBannerVisible
        ? _c("ProductBannerUpload", {
            attrs: {
              visible: _vm.productBannerVisible,
              row: _vm.upLoadBannerRow
            },
            on: {
              "update:visible": function($event) {
                _vm.productBannerVisible = $event
              },
              ok: _vm.upLoadBannerSuccess
            }
          })
        : _vm._e(),
      _vm.detailPictureVisible
        ? _c("DetailPictureDialog", {
            attrs: {
              visible: _vm.detailPictureVisible,
              row: _vm.detailPictureRow,
              type: _vm.type
            },
            on: {
              "update:visible": function($event) {
                _vm.detailPictureVisible = $event
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }